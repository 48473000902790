import React from 'react';

const Logo = ({
  title,
  now,
  imgPath
}) => (
  <div className='logo'>
    <img
      src={`${imgPath}?t=${now}`}
      alt=''
    />
    <h1 className='appTitle'>{title}</h1>
  </div>
);

export default Logo;
