import React from 'react';
// import Cookies from 'js-cookie';

import PoweredBy from './PoweredBy';
import ViewContainer from './ViewContainer';
import Spinner from './Spinner';
import * as loginService from '../services/login';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function PasswordEntry(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const [state, setState] = useState({
    isLoading: false,
    username: queryParams.get('login_hint'),
    password: '',
    infoMessage: props.infoMessage,
    errorMessage: props.errorMessage,
    isSubmittingPassword: false,
  });

  function onChange (field, value) {
    setState({ ...state, [field]: value });
  }

  function prepareForgotPassword () {
    if (!props.resetPasswordEnabled) {
      return null;
    }

    return (
      <a
        className='forgot-password'
        onClick={(event) => event.preventDefault() || navigate(`/forgotPassword?login_hint=${state.username}`)}
        href="#"
      >Forgot password?</a>
    );
  }

  async function login () {
    const {
      username,
      password,
    } = state;

    if (!password) {
      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'You must enter your password.',
      });

      return;
    }

    setState({
      ...state,
      infoMessage: 'Logging you in...',
      errorMessage: '',
      isSubmittingPassword: true,
    });

    try {
      const response = await loginService.login(username, password);
      if(response.status === 200) {
        const body = await response.json();
        window.location.assign(body.redirectPath);
        return;
      }
      else if(response.status === 401) {
        setState({
          ...state,
          infoMessage: '',
          errorMessage: 'Your username or password is incorrect.',
          isSubmittingPassword: false,
        });
        return;
      }
      
      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'Something went wrong while attempting to login. If this persists contact your system administrator.',
        isSubmittingPassword: false,
      });
      return;
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'Something went wrong while attempting to login. If this persists contact your system administrator.',
        isSubmittingPassword: false,
      });
    }
  }


  function prepareLoginForm () {
    const {
      username,
      password,
      isSubmittingPassword,
    } = state;

    if (isSubmittingPassword) {
      return (
        <Spinner />
      );
    }

    return (
      <div className='auth-form-wrapper'>
        <div className='auth-form'>
          <div className='input-container form-group'>
            <div className='back-link-container'>
              <a
                className="back-link"
                onClick={(e) => e.preventDefault() || navigate('/')}
                aria-label="Go Back"
                href="#"
              >
                <i className="fa-solid fa-circle-chevron-left" aria-hidden="true"></i>
                {/* @todo: get the pro license of Font Awesome used correctly for dev and production */}
                {/* <i className="fa-light fa-circle-chevron-left" aria-hidden="true"></i> */}
              </a>
              <label
                htmlFor='username'
              >
                Username
              </label>
            </div>
            <input id='username' name='username' type='text' className='form-control' value={username} disabled={true}/>
          </div>
          <div className='input-container form-group'>
            <label
              htmlFor='password'
            >
              Password
            </label>
            <input
              className='form-control'
              id='password'
              name='password'
              type='password'
              autoComplete='off'
              placeholder='Enter Password'
              aria-label='Password'
              required
              autoFocus={true}
              onChange={(e) => onChange('password', e.target.value)}
              value={password}
              aria-describedby="error-message"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  login();
                }
              }}
            />
          </div>
          <div className='submit-container'>
            <button
              className='btn btn-primary'
              onClick={login}
              aria-disabled={!state.password}
            >Next</button>
            {prepareForgotPassword()}
          </div>
        </div>
        <PoweredBy/>
      </div>
    );
  }

  return (
    <ViewContainer
      className='login'
      version={props.version}
      title={props.title}
      now={props.now}
      errorMessage={state.errorMessage}
      infoMessage={state.infoMessage}
    >
      {prepareLoginForm()}
    </ViewContainer>
  );
}