import moment from 'moment-timezone';

export async function usernameLogin (username) {
  const timezone = moment.tz.guess();
  const response = await fetch('/auth/api/validateUsername', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username, timezone}),
  });

  return response;
}

export async function login (username, password) {
  const timezone = moment.tz.guess();
  const response = await fetch('/auth/api/login', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username, password, timezone}),
  });

  return response;
}

export async function forgotPasswordRequest (username) {
  const response = await fetch('/auth/api/forgotPassword', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({username}),
  });

  return response;
}

export async function resetTokenExists (token) {
  return await fetch(`/auth/api/resetPassword?token=${token}`);
}

export async function resetPassword (token, password) {
  const response = await fetch('/auth/api/resetPassword', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      password,
    })
  });

  return response;
}
