import React, { useEffect, useState } from 'react';

import PoweredBy from './PoweredBy';
import ViewContainer from './ViewContainer';
import Spinner from './Spinner';
import { useLocation, useNavigate } from 'react-router';

import * as loginService from '../services/login';

// This list of allowed special characters appears in field validation error messages
// in create/edit user forms. For those messages, the list of allowed special characters comes
// from the claris-schemas package.
/**
 * @todo Get this list of allowed special characters from the claris-schemas package.
 */
const ALLOWED_SPECIAL_CHARACTERS = '@ $ ! % * ? &';

/**
 * @todo: Add client side validation of the password rules.
 */

function ResetPassword(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [state, setState] = useState({
    token: queryParams.get('token'),
    infoMessage: 'Validating reset password information...',
    errorMessage: '',
    validationComplete: false,
    isValid: false,
    isResetting: false,
    resetSuccessfullyCompleted: false,
    password: '',
    confirm: '',
  });

  // Initialize the component
  useEffect(() => {
    document.title = props.title + ' - Reset Password';

    const func = async () => {
      const isComplete = (errorMessage = '', infoMessage = '', isValid = false) => {
        setState({
          ...state,
          infoMessage,
          errorMessage: errorMessage,
          isValid,
          validationComplete: true,
        });
      };
  
      if (!state.token) {
        return isComplete();
      }
  
      try {
        const response = await loginService.resetTokenExists(state.token);
  
        switch (response.status) {
          case 200: {
            return isComplete(null, null, true);
          }
          default: {
            return isComplete();
          }
        }
      } catch (error) {
        isComplete(error.message);
      }
    }

    func();
  }, []);

  async function submit () {
    const {
      token,
      password,
      confirm,
    } = state;

    if (!password) {
      return setState({
        ...state,
        infoMessage: '',
        errorMessage: 'You must supply a new password.',
      });
    }

    if (!confirm) {
      return setState({
        ...state,
        infoMessage: '',
        errorMessage: 'You must confirm your new password.',
      });
    }

    if (password !== confirm) {
      return setState({
        ...state,
        infoMessage: '',
        errorMessage: 'The passwords you entered do not match.',
      });
    }

    // Validate password
    const passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])([A-Za-z0-9@$!%*?&]){16,40}$");
    if(!passwordPattern.test(password)) {
      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'The password does not meet complexity requirements. Please see requirements above.'
      });

      return;
    }

    setState({
      ...state,
      isResetting: true,
      infoMessage: 'Your password is being reset...',
      errorMessage: '',
    });

    try {
      const response = await loginService.resetPassword(token, password);

      if (response.status === 200) {
        setState({
          ...state,
          isResetting: false,
          resetSuccessfullyCompleted: true,
          infoMessage: '',
          errorMessage: '',
        });
        return;
      }

      setState({
        ...state,
        isResetting: false,
        infoMessage: '',
        errorMessage: 'Something went wrong, please refresh try again. If this issue persists, please contact your system administator.',
      });
    } catch (error) {
      console.warn(error);
      setState({
        ...state,
        isResetting: false,
        infoMessage: '',
        errorMessage: 'Something went wrong, please refresh try again. If this issue persists, please contact your system administator.',
      });
    }
  }

  function onChange (field, value) {
    setState({
      ...state,
      [field]: value
    });
  }

  function prepareForm () {
    const {
      password,
      confirm,
      validationComplete,
      isValid,
      isResetting,
      resetSuccessfullyCompleted,
    } = state;

    if (resetSuccessfullyCompleted) {
      return (
        <div className='reset-password-container auth-form' aria-live='polite'>
          <p>Your password has been successfully reset!</p>
          <p>
            <a
              onClick={(event) => event.preventDefault() || navigate('/login')}
              href="#"
            >Click here to go to the Login page</a>
          </p>
        </div>
      );
    }

    if (!validationComplete || isResetting) {
      return (
        <Spinner/>
      );
    }

    if (!isValid) {
      return (
        <div className='reset-password-container auth-form'>
          <p>This is not a valid reset password url.</p>
          <p><a
            onClick={(event) => event.preventDefault() || navigate('/login')}
            href="#"
          >Click here to go to the Login page</a></p>
        </div>
      );
    }

    return (
      <div className='auth-form-wrapper'>
        <div className='reset-password-container auth-form'>
          <div className='rules'>
            <p>Rules for your new password:</p>
            <ul>
              <li>at least 16 characters</li>
              <li>at least 1 upper case character</li>
              <li>at least 1 lower case character</li>
              <li>at least 1 number</li>
              <li>no spaces</li>
              <li>1 of the following characters: <br/>{ALLOWED_SPECIAL_CHARACTERS}</li>
            </ul>
          </div>
          <div className='input-container form-group'>
            <input
              className="form-control"
              name='password'
              type='password'
              autoComplete='new-password'
              placeholder='Enter new password'
              required
              onChange={(e) => onChange('password', e.target.value)}
              value={password}
            />
          </div>
          <div className='input-container form-group'>
            <input
              className="form-control"
              name='confirm'
              type='password'
              autoComplete='new-password'
              placeholder='Re-enter new password'
              required
              onChange={(e) => onChange('confirm', e.target.value)}
              value={confirm}
            />
          </div>
          <div className='submit-container'>
            <button
              className='btn btn-primary'
              onClick={submit}
              aria-disabled={!state.password || !state.confirm}
            >Submit</button>
            <a
              className="cancel"
              onClick={(event) => event.preventDefault() || navigate('/login')}
              href="#"
            >Cancel</a>
          </div>
        </div>
        <PoweredBy />
      </div>
    );
  }

  return (
    <ViewContainer
      className='reset-password'
      version={props.version}
      title={props.title}
      now={props.now}
      infoMessage={state.infoMessage}
      errorMessage={state.errorMessage}
    >
      {prepareForm()}
    </ViewContainer>
  );
}

export default ResetPassword;
