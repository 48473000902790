import React from 'react';

const Notice = ({ title, message }) => {
  return (
    <div className='notice'>
      <h4>{title}</h4>
      <p>{message}</p>
    </div>
  );
};

export default Notice;
