import React, { useEffect } from 'react';

import ViewContainer from './ViewContainer';
import Spinner from './Spinner';
import PoweredBy from './PoweredBy'

import * as loginService from '../services/login';

import { useState } from 'react';
import { useNavigate } from 'react-router';
export default function Login(props) {
  const [state, setState] = useState({
    username: '',
    infoMessage: props.infoMessage,
    errorMessage: props.errorMessage,
    isSubmittingUsername: false,
    loggedIn: false,
  });

  const navigate = useNavigate();

  function onChange (field, value) {
    setState({ ...state, [field]: value });
  }

  async function login () {
    const { username } = state;

    if (!username) {
      setState({
        ...state,
        errorMessage: 'You must enter your username.',
    });

      return;
    }

    setState({
      ...state,
      infoMessage: 'Verifying username...',
      errorMessage: '',
      isSubmittingUsername: true,
    });

    try {
      const response = await loginService.usernameLogin(username);
      const body = await response.json();
      if(body.redirectUrl) {
        window.location.assign(body.redirectUrl);
        return;
      }
      navigate(`/passwordEntry?login_hint=${username}`);
    } catch (error) {
      setState({
        ...state,
        errorMessage: error.message,
        isSubmittingUsername: false,
      });
    }
  }

  function prepareLoginForm () {
    if (state.isSubmittingUsername) {
      return (
        <Spinner />
      );
    }

    return (
      <div className='auth-form'>
        <div className='input-container form-group'>
          <label
            htmlFor='username'
          >
            Username
          </label>
          <input
            className="form-control"
            id='username'
            name='username'
            type='text'
            autoComplete='off'
            placeholder='Enter Username'
            aria-label='Username'
            required
            autoFocus={true}
            onChange={(e) => onChange('username', e.target.value)}
            value={state.username}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                login();
              }
            }}
          />
        </div>
        <div className='submit-container'>
          <button
            className='btn btn-primary'
            onClick={login}
            aria-disabled={!state.username}
          >Next</button>
        </div>
      </div>
    );
  }


  useEffect(() => {
    document.title = props.title + ' - Log In';
  }, []);


  return (
    <ViewContainer
      className='login'
      alerts={props.alerts}
      version={props.version}
      title={props.title}
      now={props.now}
      infoMessage={state.infoMessage}
      errorMessage={state.errorMessage}
    >
      {prepareLoginForm()}
      <PoweredBy />
    </ViewContainer>
  );
};