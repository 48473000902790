import React from "react";

function Alerts(props) {
  const { alerts = [] } = props;

  if (!alerts.length) {
    return null;
  }

  const icon = "alertFaIcon fa fa-regular fa-diamond-exclamation"

  if (alerts.length > 1) {
    return (
      <div
        className="alerts global"
        tabIndex={0}
      >
        <div className="gateway-alert">
          <div>
            <i className={icon} aria-hidden="true"></i>
            <span>There are multiple alerts. Log in to view all system alerts.</span>
          </div>
        </div>
      </div>
    );
  } else {
    const alert = alerts[0];
    return (
      <div
        className="alerts global"
        tabIndex={0}
      >
        <div
          className='gateway-alert'
        >
          <div>
            <i className={alert.icon} aria-hidden='true'></i>
            <span>{alert.title}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Alerts;
