import React from 'react';

import Logo from './Logo';

const ViewContainer = ({
  children = null,
  className = '',
  infoMessage,
  errorMessage,
  alertAboveBody = false,
  title,
  now
}) => {
  
  const positionClass = alertAboveBody ? ' alert-above-body' : '';
  return (
    <div className={`view-container ${className}-view-container`}>
      <Logo
        title={title ? title : 'Claris'}
        now={now}

        // @TODO: Update imgPath to handle different paths
        imgPath={"/app/claris/api/public/assets/logos/logo.png"}
      />
      <div className='content-container'>
        {!alertAboveBody && children}
        {errorMessage &&
          <p
            role="alert"
            className={'alert alert-danger' + positionClass}
          >{errorMessage}</p>
        }
        {infoMessage &&
          <p
            aria-live="polite"
            className={'alert alert-info' + positionClass}
          >{infoMessage}</p>
        }
        {alertAboveBody && children}
      </div>
    </div>
  );
};

export default ViewContainer;
