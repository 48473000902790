import React from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router';

import Notice from './components/Notice';
import Login from './components/Login';
import PasswordEntry from './components/PasswordEntry';
import Alerts from './components/Alerts';

import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import LoggedOutView from './components/LoggedOut';

function AuthenticationView(props) {
  const {
    alerts,
    version,
    title,
    legalLanguage,
  } = props;

  const router = createBrowserRouter([
    {
      path: 'passwordEntry',
      element: <PasswordEntry {...props} />
    },
    {
      path: 'forgotPassword',
      element: <ForgotPassword {...props} />
    },
    {
      path: 'resetPassword',
      element: <ResetPassword {...props} />
    },
    {
      path: 'login',
      element: <Login {...props} />,
      index: true,
    },
    {
      path: 'logout',
      element: <LoggedOutView {...props} />,
    },
    {
      path: '*',
      loader: () => {
        return redirect('/login');
      }
    }
  ], {
    basename: '/auth'
  })

  return (
      <div id="container-router">
        <div className="view-container-wrapper">
          <div className='view-container-header'>
            {Boolean(alerts && alerts.length) &&
              <div className='alerts-container'>
                <Alerts
                  alerts={alerts}
                />
              </div>
            }
            <div className='version-container'>
              <p className='version'>v{version}</p>
            </div>
          </div>
          <div className="view-container-container">
            <RouterProvider router={router} />
          </div>
          { legalLanguage &&
            <div className='auth-notice-wrapper'>
              <Notice title='Notice' message={legalLanguage} />
            </div>
          }
        </div>
      </div>
  );
}

export default AuthenticationView;
