import React from 'react';
import skylineMarkImage from '../../../shared/img/skyline-mark.svg';

const PoweredBy = ({ name = 'Claris', img = '/' + skylineMarkImage, className }) => {
  return (
    <div className={`powered-by ${className}`}>
      <span className='powered-by-text'>
        Powered By:
      </span>
      <div className='product-title'>
        <img className='skyline-mark' src={img} alt='' />
        <span className='product-name'>
          {name}
        </span>
      </div>
    </div>
  );
};

export default PoweredBy;
