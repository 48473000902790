import React from 'react';

import PoweredBy from './PoweredBy';
import ViewContainer from './ViewContainer';
import Spinner from './Spinner';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import * as loginService from '../services/login';


// Enum for the states this component can be in
const PAGE_STATE_ENUM = {
  WAITING_FOR_USERNAME: 'WAITING_FOR_USERNAME',
  SENDING_EMAIL: 'SENDING_EMAIL',
  EMAIL_SENT: 'EMAIL_SENT',
  ERROR: 'ERROR',
};

export default function ForgotPassword(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [state, setState] = useState({
    username: queryParams.get('login_hint'),
    infoMessage: props.infoMessage,
    errorMessage: props.errorMessage,
    pageState: PAGE_STATE_ENUM.WAITING_FOR_USERNAME,
  });

  async function submit () {
    const { username } = state;

    if (!username) {
      return setState({
        ...state,
        infoMessage: '',
        errorMessage: 'You must enter your username.',
      });
    }

    setState({
      ...state,
      infoMessage: 'We are sending your email now... please wait.',
      errorMessage: '',
      pageState: PAGE_STATE_ENUM.SENDING_EMAIL,
    });

    try {
      const response = await loginService.forgotPasswordRequest(username);

      if (response.status === 200) {
        setState({
          ...state,
          infoMessage: `An email with a confirmation link has been sent to your email address.`,
          errorMessage: '',
          alertAboveBody: true,
          pageState: PAGE_STATE_ENUM.EMAIL_SENT,
        });
        return;
      }

      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'Error encountered while trying to send email. Please refresh the page and try again.',
        pageState: PAGE_STATE_ENUM.ERROR,
      });
    } catch (error) {
      console.error(error);

      setState({
        ...state,
        infoMessage: '',
        errorMessage: 'Error encountered while trying to send email. Please refresh the page and try again.',
        pageState: PAGE_STATE_ENUM.ERROR,
      });
    }
  }

  function prepareForm () {
    switch (state.pageState) {
      // Show the entire form
      case PAGE_STATE_ENUM.WAITING_FOR_USERNAME:
      case PAGE_STATE_ENUM.ERROR: {
        return (
          <div className='auth-form-wrapper'>
            <div className='forgot-password-form auth-form'>
              <label htmlFor="username">Forgot your password?</label>
              <div className='input-container form-group'>
                <div className='back-link-container'>
                  <a
                    className="back-link"
                    onClick={(event) => event.preventDefault() || navigate(`/passwordEntry?login_hint=${state.username}`)}
                    aria-label="Go Back"
                    href="#"
                  >
                    <i className="fa-solid fa-circle-chevron-left" aria-hidden="true"></i>
                    {/* @todo: get the pro license of Font Awesome used correctly for dev and production */}
                    {/* <i className="fa-light fa-circle-chevron-left" aria-hidden="true"></i> */}
                  </a>
                  <label
                    htmlFor='username'
                  >
                    Username
                  </label>
                  <input
                    disabled={true}
                    className="form-control"
                    id='username'
                    name='username'
                    type='text'
                    value={state.username}
                  />
                </div>
                <label className='text-white help-block small'>
                  Please verify username to submit reset password request.
                </label>
              </div>
              <div className='submit-container'>
                <button
                  className='btn btn-primary'
                  onClick={submit}
                  aria-disabled={!state.username}
                >Submit</button>
              </div>
            </div>
            <PoweredBy />
          </div>
        );
      }

      // Show the spinner while the server processes the request
      // and sends the email
      case PAGE_STATE_ENUM.SENDING_EMAIL: {
        return (
          <Spinner/>
        );
      }

      // Display link to the login page.
      case PAGE_STATE_ENUM.EMAIL_SENT: {
        return (
          <a
            onClick={(event) => event.preventDefault() || navigate(`/login`)}
            href="#"
          >Click here to go to the Login page</a>
        );
      }

      default: {
        throw new Error(`Unknown page state "${PAGE_STATE_ENUM}"`);
      }
    }
  }

  return (
    <ViewContainer
      className='forgot-password'
      version={props.version}
      title={props.title}
      now={props.now}
      errorMessage={state.errorMessage}
      infoMessage={state.infoMessage}
      alertAboveBody={state.alertAboveBody}
    >
      {prepareForm()}
    </ViewContainer>
  );
};
