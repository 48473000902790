import { createRoot } from 'react-dom/client';
import App from './app';
import './styles/index.css'

async function main() {
  const response  = await fetch('/auth/api/data');
  const viewData = await response.json();

  const root = createRoot(document.getElementById('root'));
  root.render(<App {...viewData} version={viewData.suiteVersion} />); 
}

main()
  .catch((error) => {
    console.warn('Error while loading the auth pages.');

    throw error;
  })