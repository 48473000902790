import React, { useEffect } from 'react';
import PoweredBy from './PoweredBy';
import ViewContainer from './ViewContainer';
import { useNavigate } from 'react-router';

export default function LoggedOutView (props) {
  const {
    alerts,
    title,
    now,
    version
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
   document.title = title + ' - Logged Out';
  }, [])

    return (
      <ViewContainer
        className='loggedOut'
        alerts={alerts}
        version={version}
        title={title}
        now={now}
      >
        <div className="auth-form loggedOut-content">
          <p>You have been logged out.</p>
          <div className='submit-container flex-center'>
            <button
              className='btn btn-primary'
              onClick={() => {
               navigate('login');
               return;
              }}
            >Return to Login Page</button>
          </div>
        </div>
        <PoweredBy/>
      </ViewContainer>
    );
  }

